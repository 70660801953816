import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import {login, shopCode} from '../stores/auth';

function Signin() {

  const [gsm, setgsm] = useState('')
  const [pass, setpass] = useState('')

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogin = (e) => {
    e.preventDefault();
    //console.log(gsm+" "+pass);
    axios.post('https://api.ouno.com.tr/auth/signin.php',{
      gsm:gsm,
      pass:pass
    })
    .then(function(response){
      if(response.data.success){
        toast.success("Giriş Başarılı");
        dispatch(login(true));
        dispatch(shopCode(response.data.data.shop_code))
        navigate('/profilim');
      }else{
        toast.error("Giriş Başarısız");
      }
    })
  }


  return (
    <div className="d-flex vh-100 flex-column">
      <Toaster />
      <Header />
      <Navbar />

      <div className="container">
        <div className="row my-5">
          <div className="col-md-6 mx-auto">
            <div className="card shadow bg-white border">
              <div className="card-body">
                <h5 className="card-title">Giriş Ekranı</h5>
                <p>Formu doldurun, oturum açın.</p>

                <form>
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={(value) => setgsm(value.target.value)}
                    />
                    <label htmlFor="floatingInput">Cep Telefonu</label>
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="password"
                      className="form-control"
                      id="floatingPassword"
                      placeholder="Password"
                      onChange={(value) => setpass(value.target.value)}
                    />
                    <label htmlFor="floatingPassword">Parola</label>
                  </div>

                  <div className="mb-3">
                    <button onClick={(e) => handleLogin(e)} className="btn btn-lg btn-dark">Giriş Yap</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Signin;
