import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import {useSelector} from 'react-redux';
import Breadcrumb from '../components/Breadcrumb'
import ProductCard from '../components/ProductCard'

function Search() {
    const {search} = useSelector((state) => state.search);
    const [searchProduct, setsearchProduct] = useState([]);
    useEffect(() => {
        axios.post('https://api.ouno.com.tr/fetch/searchProduct.php',{
            q: search
        })
        .then(function(response){
            if(response.data.success === true){
            setsearchProduct(response.data.data);
            }else{
                toast.error("Böyle bir ürün bulunamadı");
            }
        },[])
    })

  return (
    <div className="d-flex vh-100 flex-column">
        <Toaster />
      <Helmet>
        <meta charSet="utf-8" />
        <title>OUNO Katalog</title>
        <link rel="canonical" href="https://katalog.ouno.com.tr/" />
      </Helmet>
      <Header />
      <Navbar />
      <div className="container">
        <div className="row my-3">
          <div className="col-md">
            <Breadcrumb category="Ara" />
          </div>
        </div>

        <ProductCard products={searchProduct} category="Ara" />
      </div>
      <Footer />
    </div>
  )
}

export default Search