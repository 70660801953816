import { createSlice } from "@reduxjs/toolkit";

const products = createSlice({
    name: "products",
    initialState: {
        products: [],
        loading: false,
        error: null,
    },
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload;
        }
    }
}
);

export const { setProducts } = products.actions;
export default products.reducer;