import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link,useNavigate } from "react-router-dom";
import {setSearch} from '../stores/search'

function Header() {
  const { user } = useSelector((state) => state.auth);
  let navigate = useNavigate();
  const {search} = useSelector((state) => state.search)
  const dispatch = useDispatch();
  const {point} = useSelector((state) => state.point);

  const handleSearch = (e) => {
    e.preventDefault();
    navigate("/ara/"+search);
  }
  return (
    <>
      <div className="header bg-white">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-3 col-lg-3 mb-3">
              <div className="logo">
                <Link className="text-decoration-none text-dark" to="/">
                  <strong>ouno</strong>
                  <span>katalog</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-12 mb-3">
              <form className="d-flex" onSubmit={(e) => handleSearch(e)}>
              <input
                type="text"
                className="form-control"
                placeholder="Ürün ara..."
                value={search}
                onChange={(e)=> dispatch(setSearch(e.target.value))}
              />
              <button className="btn"><i class="bi bi-search"></i></button>
              </form>
              
            </div>
            <div className="col-lg-3 col-12 mb-3 d-flex text-end">
              {!user ? (
                <Link
                  to="/giris-ekrani"
                  className="btn btn-danger form-control"
                >
                  <i className="bi bi-person-circle me-2"></i> Giriş Yap
                </Link>
              ) : (
                <>
                  <Link to="/" className="disabled nav-link text-danger">
                    {point} Puan
                  </Link>
                  <Link
                    className="nav-link text-decoration-none text-dark"
                    to="/profilim"
                  >
                    <i class="bi bi-person-circle me-2"></i> Hesabım
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
