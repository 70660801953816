import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import axios from "axios";

function Pages() {
  let params = useParams();
  const [page, setpage] = useState([]);
  useEffect(() => {
    axios
      .post("https://api.ouno.com.tr/fetch/pageDetails.php", {
        page_link: params.page,
      })
      .then(function (response) {
        //console.log(response);
        setpage(response.data.data);
      });
  }, []);

  return (
    <div className="d-flex vh-100 flex-column">
      <Helmet>
        <meta charSet="utf-8" />
        <title>OUNO Katalog</title>
        <link rel="canonical" href="https://katalog.ouno.com.tr/" />
      </Helmet>
      <Header />
      <Navbar />
      <div className="my-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto">
              <h3 className="text-center">{page.page_title}</h3>
              <div dangerouslySetInnerHTML={{ __html: page.page_text }}></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Pages;
