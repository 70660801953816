import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent",
        borderRadius: "10px",
        marginRight: "30px",
        opacity: "1",
        height: "50px",
        width: "50px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent",
        borderRadius: "10px",
        marginLeft: "30px",
        opacity: "1",
        height: "50px",
        width: "50px",
      }}
      onClick={onClick}
    />
  );
}

function SlickSlider({ categories }) {
  const settings = {
    dots: false,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    adaptiveHeight: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    className: "slick-center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          className: "center",
          centerMode: true,
          infinite: true,

          centerPadding: "60px",
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          className: "center",
          centerMode: true,
          infinite: true,

          centerPadding: "60px",
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const navigate = useNavigate();
  return (
    <>
      <Slider className="mb-5" {...settings}>
        {categories.map((category) => (
          <div className="p-3">
            <div
              className="card shadow border rounded p-3"
              key={category.cat_id}
            >
              <img
                onClick={() => navigate(category.cat_link)}
                src={
                  "https://katalog.ouno.com.tr/uploads/category/" +
                  category.cat_icon
                }
                className="card-img-top indeximg"
              />
              <div className="card-body fw-bold text-center">
                <a
                  className="text-dark text-decoration-none"
                  href={category.cat_link}
                >
                  {category.cat_name}
                </a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
}

export default SlickSlider;
