import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BrandsFilter from "../components/BrandsFilter";
import Breadcrumb from "../components/Breadcrumb";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import ProductCard from "../components/ProductCard";
import { setProducts} from "../stores/products";

function Categories() {
  let params = useParams();
  //const [products, setProducts] = useState([]);
  const {products} = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const [catname, setcatname] = useState('');
  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    axios
      .post("https://api.ouno.com.tr/fetch/products.php", {
        category: params.category,
      })
      .then(function (response) {
        if(response.data.success == true){
          //setProducts(response.data.data);
          dispatch(setProducts(response.data.data));
          setcatname(response.data.cat_name);
          setisloading(false);
        }
      });
  },[]);

  

  return (
    <div className="d-flex vh-100 flex-column">
      <Helmet>
        <meta charSet="utf-8" />
        <title>OUNO Katalog</title>
        <link rel="canonical" href="https://katalog.ouno.com.tr/" />
      </Helmet>
      <Header />
      <Navbar />
      <div className="container">
        {
          isloading ?
          <div className="row">
            <div className="col-md-12">
              <div className="text-center d-flex vh-100 align-items-center justify-content-center">
                <div className="spinner-border text-primary" role="status">
                </div>
              </div>
            </div>
          </div>
          :
          <div>
            <div className="row my-3">
          <div className="col-md">
            <Breadcrumb category={catname} />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-12">
            <BrandsFilter category={params.category} /> 
          </div>
        </div>

        <ProductCard products={products} category={params.ProductCard} />
            </div>
        }
        
      </div>
    </div>
  );
}

export default Categories;
