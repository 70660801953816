import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {setProducts} from "../stores/products";

function BrandsFilter({category}) {
  const [brands, setBrands] = useState([]);
  const { products } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  useEffect(() => {
    axios
      .post("https://api.ouno.com.tr/fetch/brands.php")
      .then(function (response) {
        //console.log(response);
        if (response.data.success == true) {
          setBrands(response.data.data);
        }
      });
  }, []);

  const handleSelected = (brand) => {
    //console.log(brand + " " + category);

    axios.post('https://api.ouno.com.tr/fetch/brandsToProducts.php',{
        brand_id: brand,
        cat_link: category,
    }).then(function (response) {
        //console.log(response);
        if(response.data.success == true){
            dispatch(setProducts(response.data.data));
        }else{
            toast.error("Aradığınız marka için ürün bulunamadı.");
        }

    })

  };
  return (
    <div>
        <Toaster />
      <select
        className="form-select"
        onChange={(e) => handleSelected(e.target.value)}
      >
        <option value="" disabled selected>
          -- Marka Seçiniz --
        </option>
        {brands.map((brand) => (
          <option key={brand.brand_id} value={brand.brand_id}>
            {brand.brand_name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default BrandsFilter;
