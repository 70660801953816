import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

function Navbar() {
  const [navData, setnavData] = useState([]);

  useEffect(() => {
    axios
      .post("https://api.ouno.com.tr/fetch/categories.php")
      .then(function (response) {
        setnavData(response.data);
      });
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="/tum-urunler">
                  Tüm Ürünler
                </a>
              </li>
              {
              navData.map((item) => (
               
                  <li key={item.cat_id} className="nav-item">
                    <a className="nav-link" href={"/" + item.cat_link}>
                      {item.cat_name}
                    </a>
                  </li>
               
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
