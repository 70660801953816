import Home from "./views/Home";
import Signin from "./views/Signin";
import Categories from "./views/Categories";
import Profile from "./views/Profile";
import Product from "./views/Product";
import Pages from "./views/Pages";
import Search from "./views/Search";

export const routes = [
  {
    id: 1,
    path: "/",
    exact: true,
    element: Home,
    auth: false,
  },
  {
    id: 2,
    path: "/giris-ekrani",
    exact: true,
    element: Signin,
    auth: false,
  },
  {
    id: 3,
    path: "/:category",
    exact: true,
    element: Categories,
    auth: false,
  },
  {
    id: 4,
    path: "/profilim",
    exact: true,
    element: Profile,
    auth: true,
  },
  {
    id: 5,
    path: "/:category/:product",
    exact: true,
    element: Product,
    auth: false,
  },
  {
    id: 6,
    path: "/sayfa/:page",
    exact: true,
    element: Pages,
    auth: false,
  },
  {
    id:7,
    path:'/ara/:search',
    exact:true,
    element:Search,
    auth:false,
  }
];
