import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import SlickSlider from "../components/SlickSlider";
import "../Fancybox";
import Fancybox from "../Fancybox";

function Product() {
  let params = useParams();
  const [productData, setproductData] = useState([]);
  const [isloading, setisloading] = useState(true);

  const {user} = useSelector((state) => state.auth);
 
  useEffect(() => {
    axios
      .post("https://api.ouno.com.tr/fetch/productDetails.php", {
        product_link: params.product,
      })
      .then(function (response) {
        //console.log(response);
        response.data.success
          ? setproductData(response.data.data)
          : toast.error("Böyle bir ürün bulunamadı");
        setisloading(false);
      });
  }, []);

  return (
    <div className="d-flex vh-100 flex-column">
      <Toaster />
      <Helmet>
        <meta charSet="utf-8" />
        <title>OUNO Katalog</title>
        <link rel="canonical" href="https://katalog.ouno.com.tr/" />
      </Helmet>
      <Header />
      <Navbar />
      <div className="container">
        {isloading ? (
          <div className="row">
            <div className="col-md-12">
              <div className="text-center d-flex vh-100 align-items-center justify-content-center">
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="row my-3">
              <div className="col-md">
                <Breadcrumb
                  category={productData.cat_name}
                  category_link={productData.cat_link}
                  product={productData.product_name}
                />
              </div>
            </div>
            <div className="row">
              <Fancybox>
                <div className="col-md-4">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide border p-2 bg-white shadow rounded"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          data-fancybox="gallery"
                          data-src={
                            "https://katalog.ouno.com.tr/uploads/" +
                            productData.product_img1
                          }
                          src={
                            "https://katalog.ouno.com.tr/uploads/" +
                            productData.product_img1
                          }
                          className="d-block w-100 p-2"
                          alt="..."
                        />
                      </div>

                      {productData.product_img2 ? (
                        <div className="carousel-item">
                          <img
                            data-fancybox="gallery"
                            data-src={
                              "https://katalog.ouno.com.tr/uploads/" +
                              productData.product_img2
                            }
                            src={
                              "https://katalog.ouno.com.tr/uploads/" +
                              productData.product_img2
                            }
                            className="d-block w-100  p-2"
                            alt="..."
                          />
                        </div>
                      ) : null}

                      {productData.product_img3 ? (
                        <div className="carousel-item">
                          <img
                            data-fancybox="gallery"
                            data-src={
                              "https://katalog.ouno.com.tr/uploads/" +
                              productData.product_img3
                            }
                            src={
                              "https://katalog.ouno.com.tr/uploads/" +
                              productData.product_img3
                            }
                            className="d-block w-100 p-2"
                            alt="..."
                          />
                        </div>
                      ) : null}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </Fancybox>

              <div className="col-md">
                <div className="card shadow rounded h-100">
                  <div className="card-body">
                    <h2 className="h5">{productData.product_name}</h2>
                    {
                    user ? (
                      <p className="text-danger fs-4 fw-bold">{productData.product_puan > 0 ? productData.product_puan + " Puan" : null}</p>
                    ) : (
                      <></>
                    )
                  }
                    <div
                      dangerouslySetInnerHTML={{
                        __html: productData.product_featured,
                      }}
                    />

                    {productData.product_btnsale ? (
                      <a
                        className="btn btn-outline-secondary"
                        target="_blank"
                        href={productData.product_btnsale}
                      >
                        Satın Al
                      </a>
                    ) : null}
                    {productData.product_btnrent ? (
                      <a
                        className="btn btn-outline-secondary ms-3"
                        target="_blank"
                        href={productData.product_btnrent}
                      >
                        Kirala
                      </a>
                    ) : null}

                    {
                      productData.product_btnvideo ? (
                        <a className="btn btn-outline-secondary ms-3" target="_blank" href={productData.product_btnvideo}>
                          Video Görüntüle
                        </a>
                      ) : null
                    }

                    {
                      productData.product_btndetails ? (
                        <a className="btn btn-outline-secondary ms-3" target="_blank" href={productData.product_btndetails}>
                          Detaylı Bilgi
                        </a>
                      ) : null
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-3 mb-5">
              <div className="col-md">
                <div className="card shadow bg-white">
                  <div className="card-body">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Ürün Açıklaması
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Teknik Özellikleri
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content my-3" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: productData.product_text,
                          }}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: productData.product_technical,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Product;
