import { createSlice } from '@reduxjs/toolkit'

export const auth = createSlice({
  name: 'auth',
  initialState: {
    user: false,
    shopCode: '',
  },
  reducers: {
    login:(state, action) =>{
        state.user = action.payload
    },
    logout:state => {
        state.user = false
    },
    shopCode: (state, action) => {
        state.shopCode = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { login, logout, shopCode } = auth.actions

export default auth.reducer