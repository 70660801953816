import { createSlice } from "@reduxjs/toolkit";

const point = createSlice({
  name: "point",
  initialState: {
    point: 0,
  },
  reducers: {
    setPoint: (state, action) => {
      state.point = action.payload;
    },
  },
});

export const { setPoint } = point.actions;
export default point.reducer;
