import React from "react";

function Breadcrumb({category, category_link, product = null}) {
  return (
    <div>
      <nav className="breadcrumbNav" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Anasayfa</a>
          </li>
          
          {
            product ?
              <>
              <li className="breadcrumb-item" aria-current="page">
                <a href={"/"+category_link}>{category}</a>
              </li> 
              <li className="breadcrumb-item active">
                {product}
              </li>
              </>
              : 
              <li className="breadcrumb-item" aria-current="page">
              {category}
              </li> 
          }
        </ol>
      </nav>
    </div>
  );
}

export default Breadcrumb;
