import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function FeaturedCard() {
  const [featuredData, setFeaturedData] = useState([]);
  const navigate = useNavigate();
  const {user} = useSelector((state) => state.auth);
  useEffect(() => {
    axios
      .post("https://api.ouno.com.tr/fetch/featuredProducts.php")
      .then(function (response) {
        setFeaturedData(response.data.data);
      });
  }, []);
  return (
    <div className="container my-5">
      <div className="row text-center mb-4">
        <div className="col-md-12">
          <h3>ÖNE ÇIKARILANLAR</h3>
          <p>Bu ayın öne çıkartılan ürünlerini görüntülüyorsunuz.</p>
        </div>
      </div>
      <div className="row">
        {featuredData.map((item) => (
          <div className="col-md-3 col-6 mb-3">
            <div className="card featuredCard h-100">
              <img
                onClick={() => navigate("../"+item.cat_link + "/"+item.product_link)}
                src={"https://katalog.ouno.com.tr/uploads/" + item.product_img1}
                className="card-img-top card-img"
                alt="..."
              />
              {
                item.product_badge ? 
              <img src={"https://katalog.ouno.com.tr/uploads/badge/"+item.product_badge} height="75" className="position-absolute rozet" />

                : null
              }

              <div className="card-body">
                {item.product_name}
                {
                    user ? (
                      <p className="m-0 fs-5 text-danger fw-bold">{item.product_puan > 0 ? item.product_puan + " Puan" : null}</p>
                    ) : (
                      <></>
                    )
                  }
              </div>
              
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeaturedCard;
