import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Jumbotron from "../components/Jumbotron";
import Navbar from "../components/Navbar";
import SlickSlider from "../components/SlickSlider";
import axios from "axios";
import FeaturedCard from "../components/FeaturedCard";
import ReactGA from "react-ga4";

ReactGA.initialize("G-24WHF67LJ1");
ReactGA.send("pageview");

function Home() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.ouno.com.tr/fetch/categories.php")
      .then(function (response) {
        //console.log(response);
        setCategories(response.data);
      });
  }, []);
  return (
    <div className="d-flex vh-100 flex-column">
      <Helmet>
        <meta charSet="utf-8" />
        <title>OUNO Katalog</title>
        <link rel="canonical" href="https://katalog.ouno.com.tr/" />
      </Helmet>
      <Header />
      <Navbar />
      <div>
        <SlickSlider categories={categories} />
      </div>

      <FeaturedCard />
      <Footer />
    </div>
  );
}

export default Home;
