import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import searchReducer from "./search";
import productsReducer from "./products";
import pointReducer from "./point";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  auth: authReducer,
  search: searchReducer,
  products: productsReducer,
  point: pointReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
});

export default store;

/*
export default configureStore({
  reducer: {
    auth:authReducer,
    search: searchReducer,
    products: productsReducer,
  },
})*/
