import React, { useEffect, useState } from "react";
import axios from "axios";

function Footer() {
  const [page, setpage] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.ouno.com.tr/fetch/pages.php")
      .then(function (response) {
        //console.log(response);
        setpage(response.data.data);
      });
  }, []);

  return (
    <>
      <div className="footer bg-light p-4 mt-auto">
        <div className="container">
          <div className="row">
            <div className="col-md">
              <ul className="nav justify-content-center">
                {page.map((item) => (
                  <li key={item.page_id} className="nav-item">
                    <a className="nav-link" aria-current="page" href={"/sayfa/"+item.page_link}>
                      {item.page_title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="row my-2 text-center">
            <div className="col-md">
              <span className="copyFooter">
                &copy; 2022 Ouno Bilişim ve Dağıtım A.Ş. Her Hakkı Saklıdır.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
