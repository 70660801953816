import "./App.css";
import { routes } from "./routes";
import { Route, Routes, Navigate } from "react-router-dom";
import {useSelector} from 'react-redux'

function App() {

  const {user} = useSelector(state => state.auth)

  return (
    <Routes>
      {
      routes.map((route) => (
        <Route key={route.id} path={route.path} element={route.auth && !user ? (<Navigate replace to='/' />):(<route.element/>)}  />
        )
      )
      }
    </Routes>
  );
}

export default App;
