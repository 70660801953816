import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

function ProductCard({ products }) {
  let params = useParams();
  const navigate = useNavigate();
  const {user} = useSelector((state) => state.auth);
  return (
    <>
      <div className="row productBox">
        {products.map((product) => (
          <div key={product.product_id} className="col-md-3 col-6 mb-4">
            <div className="card shadow">
              <img
                src={"https://katalog.ouno.com.tr/uploads/"+product.product_img1}
                className="card-img-top card-img"
                onClick={() => navigate("../"+product.cat_link + "/"+product.product_link)}
              />
              
              <div className="card-body">
                <h5 className="card-title m-0 fs-6 text-capitalize fw-bold">
                  <a
                    href={"../"+product.cat_link + "/"+product.product_link}
                    className="text-dark text-decoration-none"
                  >
                    {product.product_name}
                  </a>
                  {
                    user ? (
                      <p className="m-0 fs-5 text-danger fw-bold">{product.product_puan > 0 ? product.product_puan + " Puan" : null}</p>
                    ) : (
                      <></>
                    )
                  }
                </h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ProductCard;
