import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import {logout} from '../stores/auth';
import axios from 'axios'
import point, {setPoint} from '../stores/point';
function Profile() {

  const {user, shopCode} = useSelector(state => state.auth);
  const {point} = useSelector(state => state.point);
  const dispatch = useDispatch();


  useEffect(() => {
    
    axios.post("https://api.ouno.com.tr/logo/GetBayiPuanOzet.php",
    {
      shopCode: shopCode
    })
    .then(function(response){
      console.log(response);
      dispatch(setPoint(response.data.data.BayiPuan));
    })

  }, [])


  return (
    <div className="d-flex vh-100 flex-column">
      <Helmet>
        <meta charSet="utf-8" />
        <title>OUNO Katalog</title>
        <link rel="canonical" href="https://katalog.ouno.com.tr/" />
      </Helmet>
      <Header />
      <Navbar />

      <div className='container'>
        <div className='row my-4'>
          <div className='col-md-6 mx-auto'>
            <button onClick={() => dispatch(logout())} className='btn btn-dark form-control' >Oturumu Sonlandır</button>
          </div>
        </div>
        <div className='row my-3'>
          <div className='col-md-6 mx-auto'>
            <div className='card'>
              <div className='card-body text-center'>
              <i class="bi bi-wallet2 fs-1"></i>
              <p className='fw-bold m-0'>Bakiye</p>
              <p className='text-muted m-0'>{point} Puan</p>
              </div>
            </div>
          </div>
        </div>

        <div className='row my-5'>
          <div className='col-md-6 mx-auto'>
            <form>
              <div className='mb-3'>
                <h3 className='fw-bold'>Şifre Değiştir</h3>
              </div>
              <div className='mb-3'>
                <label className='form-label'>Cep Telefonunuz</label>
                <p className='text-muted fw-bold'>05498273653</p>
              </div>

              <div className='mb-3'>
                <label className='form-label'>Mevcut Şifreniz</label>
                <input type="password" className="form-control" />
              </div>
              <hr />
              <div className='mb-3'>
                <label className='form-label'>Yeni Şifreniz</label>
                <input type="password" className="form-control" />
              </div>
              <div className='mb-3'>
                <label className='form-label'>Yeni Şifreniz (Tekrar)</label>
                <input type="password" className="form-control" />
              </div>
              <div className='mb-3'>
                <button type='submit' className='btn btn-dark'>Güncelle</button>
              </div>
            </form>
          </div>
        </div>

      </div>

      <Footer />
    </div>
  )
}

export default Profile